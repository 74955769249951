@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
}

.active-menu {
    display: block !important;
    text-align: center;
}

.menu-item.active {
    color: black;
    text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.214);
}

.input-container:focus-within label {
    transform: translate(0, 8px) scale(0.8);
}

.input-container .filled {
    transform: translate(0, 8px) scale(0.8);
}

.input-container label {
    pointer-events: none;
    transform: translate(0, 17px) scale(1);
    transform-origin: top left;
    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    line-height: 1;
    left: 16px;
}

.input-container input {
    height: 50px;
    padding: 20px 20px 4px 16px;
    line-height: 1.5;
    outline: none;
    box-shadow: none;
    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    color: #54595f;
}

.input-container input:focus {
    box-shadow: 0 0 0 1px #54595f;
    color: #000;
}

.input-container select {
    height: 50px;
    padding: 20px 40px 4px 16px;
    line-height: 1.5;
    outline: none;
    box-shadow: none;
    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    color: #54595f;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: linear-gradient(45deg, transparent 50%, gray 50%),
        linear-gradient(135deg, gray 50%, transparent 50%), linear-gradient(to right, #ccc, #ccc);
    background-position: calc(100% - 20px) calc(1em + 6px), calc(100% - 15px) calc(1em + 6px), calc(100% - 2em) 0.5em;
    background-size: 5px 5px, 5px 5px, 1px 2em;
    background-repeat: no-repeat;
}

.input-container select:focus {
    box-shadow: 0 0 0 1px #54595f;
    color: #000;
}

.inline-select {
    color: #2e3134;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: linear-gradient(45deg, transparent 50%, gray 50%),
        linear-gradient(135deg, gray 50%, transparent 50%), linear-gradient(to right, #ccc, #ccc);
    background-position: calc(100% - 17.5px) calc(1em - 1px), calc(100% - 12.5px) calc(1em - 1px),
        calc(100% - 1.75em) 0.2em;
    background-size: 5px 5px, 5px 5px, 1px 1.75em;
    background-repeat: no-repeat;
}

.mini-loader {
    display: inline-block;
    width: 25px;
    height: 25px;
    border: 3px solid rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    border-top-color: #2e3134;
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
}

.mini-loader.light {
    border: 3px solid rgba(255, 255, 255, 0.2);
    border-top-color: #fff;
}

@keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
    }
}
@-webkit-keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
    }
}

.lds-grid {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-grid div {
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #b8eefd;
    animation: lds-grid 1.2s linear infinite;
}

.lds-grid div:nth-child(1) {
    top: 8px;
    left: 8px;
    animation-delay: 0s;
}

.lds-grid div:nth-child(2) {
    top: 8px;
    left: 32px;
    animation-delay: -0.4s;
}

.lds-grid div:nth-child(3) {
    top: 8px;
    left: 56px;
    animation-delay: -0.8s;
}

.lds-grid div:nth-child(4) {
    top: 32px;
    left: 8px;
    animation-delay: -0.4s;
}

.lds-grid div:nth-child(5) {
    top: 32px;
    left: 32px;
    animation-delay: -0.8s;
}

.lds-grid div:nth-child(6) {
    top: 32px;
    left: 56px;
    animation-delay: -1.2s;
}

.lds-grid div:nth-child(7) {
    top: 56px;
    left: 8px;
    animation-delay: -0.8s;
}

.lds-grid div:nth-child(8) {
    top: 56px;
    left: 32px;
    animation-delay: -1.2s;
}

.lds-grid div:nth-child(9) {
    top: 56px;
    left: 56px;
    animation-delay: -1.6s;
}

@keyframes lds-grid {
    0%,
    100% {
        opacity: 1;
    }
    50% {
        opacity: 0.2;
    }
}

.loading-screen {
    opacity: 1;
    transition: opacity 0.4s ease-in-out;
}

.loading-screen.fadeOut {
    opacity: 0;
}

.fade-element {
    opacity: 0;
    transition: opacity 0.4s ease-in-out;
}

.fade-element.fadeIn {
    opacity: 1;
}

.googleAutoComplete div:nth-child(3) {
    border-radius: 25px;
    height: 50px;
    font-weight: 300;
    padding-left: 5px;
}

.accordion-gradient {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.98) 75%, #fff 100%);
}

.rich-text-format p {
    margin-bottom: 15px;
}

.rich-text-format p b {
    font-weight: 600;
}

.rich-text-format p:last-child {
    margin-bottom: 0;
}

.rich-text-format a {
    text-decoration: underline;
    font-weight: 600;
}
